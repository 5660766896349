import axios from 'axios';

import { WidgetAPIModel, WidgetDataModel, WidgetType, WidgetUpdateModel } from '@/models/Widgets';

class WidgetsAPI {
  static getWidgets = async ({ accountId }: { accountId: string }) =>
    await axios.get<WidgetAPIModel>(`/widget/account/${accountId}?platform=desktop`);

  static getAllWidgets = async () => await axios.get<WidgetType[]>('/widget');

  static getWidgetData = async ({ accountId, type }: { accountId: string; type: WidgetType }) =>
    await axios.get<WidgetDataModel>(`/widget/${accountId}/${type}`);

  static updateWidgetData = async ({ accountId, data }: { accountId: string; data: WidgetUpdateModel }) =>
    await axios.put<WidgetAPIModel>(`/widget/account/${accountId}`, data);
}

export default WidgetsAPI;
