import axios from 'axios';

import { PriceResponse } from '@/models/Payments';

class PaymentAPI {
  static getPriceList = async (priceIds: string[]) =>
    await axios.get<PriceResponse[]>('/payment/web/prices', {
      params: {
        priceIds: priceIds,
      },
      paramsSerializer: {
        indexes: true,
      },
    });

  static getPaymentIntent = async ({
    accountId,
    draftCampaignId,
    priceId,
    referralId,
  }: {
    accountId: string;
    draftCampaignId: string;
    priceId: string;
    referralId?: string | null;
  }) =>
    await axios.post<{ sessionId: string; clientSecret: string }>('/payment/web/create-payment-session', {
      accountId,
      draftCampaignId,
      priceId,
      referralId,
    });

  static getSubscriptionPaymentIntent = async ({
    accountId,
    priceId,
    referralId,
  }: {
    accountId: string;
    priceId: string;
    referralId?: string | null;
  }) =>
    await axios.post<{ sessionId: string; clientSecret: string }>('/payment/web/create-subscription-session', {
      accountId,
      priceId,
      referralId,
    });

  static getPaymentSessionStatus = async ({ sessionId }: { sessionId: string }) =>
    await axios.get<{ status: string }>(`/payment/web/payment-session-status/${sessionId}`);

  static postSubscriptionManageUrl = async ({ accountId, returnUrl }: { accountId: string; returnUrl: string }) =>
    await axios.post<{ url: string }>('payment/web/create-customer-portal', { accountId, returnUrl });
}

export default PaymentAPI;
