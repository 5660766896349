import axios from 'axios';

import { ArtistLatestReleaseModel, SpotifyArtistModel } from '@/models/Spotify';

let url = 'https://api.unhurd.co.uk/onboarding';
if (import.meta.env.VITE_NODE_ENV === 'development') {
  url = 'https://api.unhurd.co.uk/test-onboarding';
}

class OnboardingAPI {
  static searchArtist = async ({ query }: { query?: string }) =>
    await axios.get(`${url}/artist/search?query=${query || ''}&type=artist&limit=50&offset=0`);

  static getArtist = async ({
    spotifyArtistId,
    spotifyArtistUrl,
  }: {
    spotifyArtistId?: string;
    spotifyArtistUrl?: string;
  }) => {
    let urlExtension = '/artist';

    if (spotifyArtistId) {
      urlExtension = `${urlExtension}?spotifyArtistId=${spotifyArtistId}`;
    }

    if (spotifyArtistUrl) {
      urlExtension = `${urlExtension}?spotifyArtistUrl=${spotifyArtistUrl}`;
    }
    return await axios.get<SpotifyArtistModel>(`${url}${urlExtension}`);
  };

  static getArtistLatestRelease = async ({ spotifyArtistId }: { spotifyArtistId: string }) => {
    return await axios.get<ArtistLatestReleaseModel>(`${url}/artist/${spotifyArtistId}/latest-release`);
  };
}

export default OnboardingAPI;
