import axios from 'axios';

import { PartnerPerk } from '@/models/PartnerPerks';

class PartnerPerksAPI {
  static getPartnerPerks = async ({ accountId }: { accountId: string }) =>
    await axios.get<PartnerPerk[]>(`/partner-perk/account/${accountId}`);
}

export default PartnerPerksAPI;
